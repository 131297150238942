import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "./client.css";
import { documentView } from "../../config/service";
import moment from "moment";
import MadicalQuestionnaire from "./MedicalQuestionnaire";
import { useTranslation } from "react-i18next";
import profile from "../../assets/images/dashboard/docProfile.png";
import { CheckIcon } from "../common/icon";
const ClientPastRequest = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qid, setQId] = useState();
  const lang = localStorage.getItem("lang");
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const viewPdf = (document) => {
    document.forEach(async (element) => {
      if (element.type == "Summary") {
        const data = {
          url: element.path,
        };
        const response = await documentView(data);
        setTimeout(() => {
          window.open(response.data, "_blank");
        });
      }
    });
  };
  const handleMeeting = (data) => {
    const obj = {
      consultation: data?.consultation,
      DoctorId: data?.DoctorId,
      patientAge: data?.patientAge,
      partnerId:
      data?.partnerId && data.partnerId.length > 0
          ? data.partnerId[0]
          : undefined,
    };
    localStorage.setItem("meetData", JSON.stringify(obj));
    navigate(`/meetingrequest?mId=${data?._id}`, { state: { pastMeetingDetail: data } });
  };
  return (
    <div>
      <MadicalQuestionnaire
        handleClose={props.handleClose5}
        medicalq={props.medicalq}
        qid={qid}
        qData={props.qData}
        readOnly={true}
      />
      <section className="userWrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="userInnerWrapper userInnerWrapB">
                {props.loader ? (
                  <h2 className="loader-text"> {t("Please wait")}....</h2>
                ) : (
                  <ul>
                    {props.allAppointment.length != 0 ? (
                      props.allAppointment
                        .sort((objA, objB) => {
                          const dateA = objA.scheduledDate
                            ? new Date(objA.scheduledDate)
                            : new Date(objA.createdAt);
                          const dateB = objB.scheduledDate
                            ? new Date(objB.scheduledDate)
                            : new Date(objB.createdAt);

                          return dateB - dateA;
                        })
                        .map((data, i) => {
                          let summaryPdf = data?.documents.find(
                            (element) => element.type === "Summary"
                          );

                          return (
                            <li key={i} className="updated-design">
                              <div className="cfBase">
                                <div className="cfFirst">
                                  <div className="cfImgbg">
                                    <div className="cfImgmain">
                                      {data.doctorDetails ? (
                                        <img
                                          src={
                                            data.doctorDetails?.croppedAvatar
                                              ? data.doctorDetails
                                                  ?.croppedAvatar
                                              : profile
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        <img src={profile} alt="" />
                                      )}
                                    </div>
                                    <div className="doctorInfo">
                                      <div className="cfDate">
                                        <h3>
                                          {data?.scheduledDate
                                            ? moment(
                                                data?.scheduledDate
                                              ).format("DD.MM.YYYY")
                                            : moment(data?.createdAt).format(
                                                "DD.MM.YYYY"
                                              )}
                                        </h3>
                                        <div className="cfDay">
                                          <span>
                                            {" "}
                                            {data?.scheduledDate
                                              ? t(
                                                  weekday[
                                                    new Date(
                                                      data?.scheduledDate
                                                    ).getDay()
                                                  ]
                                                )
                                              : t(
                                                  weekday[
                                                    new Date(
                                                      data?.createdAt
                                                    ).getDay()
                                                  ]
                                                )}
                                          </span>
                                          <span>
                                            {data?.scheduledDate
                                              ? moment(
                                                  data?.scheduledDate
                                                ).format("HH:mm")
                                              : moment(data?.createdAt).format(
                                                  "HH:mm"
                                                )}
                                          </span>
                                        </div>
                                      </div>
                                      <h2>
                                        {t(data?.doctorDataScheduled?.title)}{" "}
                                        {`${data?.doctorDetails?.first_name} ${data?.doctorDetails?.last_name} `}{" "}
                                      </h2>
                                      <div className="consultation-info">
                                        <h4>
                                          {lang === "HE"
                                            ? data?.consultation?.heb
                                            : data?.consultation?.eng}
                                        </h4>
                                        {data?.isFollowUp ? (
                                          <h4>{t("Follow Up Meeting")} </h4>
                                        ) : null}
                                      </div>
                                      {data?.doctorDataScheduled?.experience ? (
                                        <div className="cfLocate">
                                          {
                                            data?.doctorDataScheduled
                                              ?.experience
                                          }
                                        </div>
                                      ) : null}

                                      {data?.isIDF !== "IDF" &&
                                      props?.mode !== "IDF" &&
                                      ((data?.meetingType !==
                                        "PrescriptionOnly" &&
                                        data?.statuses === "Done") ||
                                        data?.statuses === "Scheduled") ? (
                                        <span
                                          className="revisit-link"
                                          onClick={() => handleMeeting(data)}
                                        >
                                          {t("Follow Up Meeting Btn")}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="cflast">
                                  <div className="cfInfo">
                                    <div className="cfChk">
                                      {data.summary &&
                                      data.documents.length != 0 &&
                                      summaryPdf ? (
                                        <>
                                          <CheckIcon />
                                          <h3 className="active">
                                            {" "}
                                            {t("Summary/Prescription")}
                                          </h3>
                                        </>
                                      ) : (
                                        <h3 className="active no_document">
                                          {" "}
                                          {t("Summary/Prescription")}
                                        </h3>
                                      )}
                                    </div>

                                    {data?.summary &&
                                    data?.documents?.length !== 0 &&
                                    summaryPdf ? (
                                      <button
                                        className="active"
                                        onClick={() =>
                                          viewPdf(
                                            data.documents.length != 0
                                              ? data.documents
                                              : ""
                                          )
                                        }
                                      >
                                        {t("download")}
                                      </button>
                                    ) : (
                                      <button>{t("not ready")}</button>
                                    )}
                                  </div>
                                  {data?.meetingType !== "PrescriptionOnly"
                                    ? props.mode !== "IDF" && (
                                        <>
                                          <div className="cfInfo">
                                            <div className="cfChk">
                                              {data.survey?.mainComplaint ||
                                              data.survey?.medicalHistory ||
                                              data.survey?.medications ? (
                                                <>
                                                  <CheckIcon />
                                                  <h3 className="active ">
                                                    {t("Medical questionnaire")}
                                                  </h3>
                                                </>
                                              ) : (
                                                <h3 className="active no_document">
                                                  {t("Medical questionnaire")}
                                                </h3>
                                              )}
                                            </div>
                                            {data.survey?.mainComplaint ||
                                            data.survey?.medicalHistory ||
                                            data.survey?.medications ? (
                                              <button
                                                className="active"
                                                onClick={() => (
                                                  props.setMedicalQ(true),
                                                  setQId(data._id),
                                                  props.setQData(data.survey)
                                                )}
                                              >
                                                {t("View")}
                                              </button>
                                            ) : (
                                              <button className="">
                                                {t("not ready")}
                                              </button>
                                            )}
                                          </div>

                                          <div className="cfInfo">
                                            <div className="cfChk">
                                              <h3 className="active no_document">
                                                {" "}
                                                {t("Invoice")}
                                              </h3>
                                            </div>
                                            <button>
                                              {t("Sent to your email")}
                                            </button>
                                          </div>
                                        </>
                                      )
                                    : null}
                                </div>
                              </div>
                            </li>
                          );
                        })
                    ) : (
                      <h3 className="no_meeting">
                        {" "}
                        {t("You have no past meeting")}{" "}
                      </h3>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ClientPastRequest;
