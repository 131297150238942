import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Layout from "../layout/layout";
import AgeForm from "./ageForm";
import ConsultingField from "./consultingField";
import ReferenceDetails from "./referenceDetails";
import FavourableTimes from "./favourableTimes";
import ConfirmationDetails from "./confirmationDetails";
import "./index.css";
import { useTranslation } from "react-i18next";
import { adminAllConsultation, allConsultation } from "../../config/service";
import Timer from "../../My Queues/Timer";
import AdminConfirmation from "./AdminConfirmation";
import { Network } from "../../config/axios";
import SupportChat from "../supportChat/supportChat";

const UTMArray = [
  {
    id: 0,
    key: "lifestyle2023",
    value: "430",
  },
  {
    id: 1,
    key: "freehidoc",
    value: "חינם",
  },
  {
    id: 2,
    key: "hidoc30",
    value: "350",
  },
  {
    id: 3,
    key: "freehidocforthesouth",
    value: "חינם",
  },
  {
    id: 4,
    key: "60",
    value: "430",
  },
];

const MeetingRequestForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const utm = searchParams.get("utm");
  const docId = searchParams.get("docId");
  let setRefStep = searchParams.get("mId");
  let pastMeetingObject = JSON.parse(localStorage.getItem("meetData"));
  const lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  const expertise = localStorage.getItem("expertise");
  // const storagePartnerId = localStorage.getItem("partnerId");
  const storagePartnerId = searchParams.get("partnerId");
  const [doctorObj, setDoctorObj] = useState("");
  const [selectStep, setSelectStep] = useState("age");

  const [categoriesData, setcategoriesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [consultField, setConsultField] = useState("");
  const [formData, setformData] = useState({});
  const [refrenceData, setRefrenceData] = useState({});
  const handleStep = (step) => {
    setSelectStep(step);
  };

  const filteredUTM = UTMArray.filter((value) => {
    return value?.key?.toLowerCase()?.includes(utm?.toLowerCase());
  });

  const { pastMeetingDetail } = location.state || {};

  useEffect(() => {
    setRefrenceData(pastMeetingDetail);
  }, [pastMeetingDetail]);

  useEffect(() => {
    consultingFields();
  }, [expertise]);

  useEffect(() => {
    if (categoriesData.length > 0 && docId) {
      doctorDataById(docId);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (
      pastMeetingObject &&
      pastMeetingObject?.partnerId === "64d326cd869f4f8664538775" &&
      setRefStep
    ) {
      const updatedFormValue = {};
      updatedFormValue.partnerId = "64d326cd869f4f8664538775";
      setformData((prev) => ({ ...prev, ...updatedFormValue }));
    }
  }, []);

  useEffect(() => {
    if (setRefStep && pastMeetingObject.consultation) {
      setSelectStep("reference");
    } else if (doctorObj?.patientsCategory?.length > 1) {
      setSelectStep("consult");
    } else {
      setSelectStep("age");
    }
    consultingFields();
  }, [searchParams]);

  const doctorDataById = async (docId) => {
    try {
      const response = await Network().get(`/users/getDoctorsById/${docId}`);
      const {
        data: { statusCode, doctor },
      } = response || {};
      if (statusCode === 200) {
        setDoctorObj(doctor[0]);
        if (categoriesData && categoriesData?.length > 0) {
          const mainObj = doctor[0]?.consultationSpeciality
            ?.map((el) => {
              const reqData = categoriesData?.filter(
                (el2) => el.toString() === el2?._id.toString()
              )[0];
              return reqData;
            })
            .filter((el) => el);
          setFilteredData(mainObj);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const consultingFields = async () => {
    const response = await (localStorage.getItem("role") === "admin"
      ? adminAllConsultation()
      : allConsultation());
    setcategoriesData(response.data.result);
    setFilteredData(response.data.result);
    if (response && docId) {
      doctorDataById(docId);
    }

    if (storagePartnerId === "64d326cd869f4f8664538775") {
      const results = response?.data?.result;
      const filteredArray = results.filter((elem) => {
        if (elem.partners.length > 0) {
          const isMatch = elem.partners.findIndex(
            (elem) => elem._id === storagePartnerId
          );
          if (isMatch >= 0) return true;
        }
        return false;
      });
      setcategoriesData(filteredArray);
    }

    // filter single data
    if (expertise !== "null") {
      let fliterConsultField = response?.data?.result.filter((x) => {
        const field = expertise === x?.heb || expertise === x?.eng;
        return field;
      });
      setConsultField(fliterConsultField[0]);
    } else {
      setConsultField("");
    }
  };
  return (
    <Layout>
      <div className="step-form">
        <div className="step-form-section">
          <div className="step-listing">
            <div className="container">
              <ul className={`${lang === "EN" ? "ulleftside" : "ulrightside"}`}>
                {setRefStep ? (
                  <>
                    <div
                      className={`${
                        selectStep === "age" ? "active-step" : "disabled-class"
                      } ${lang === "EN" ? "leftside" : "rightside"}`}
                    >
                      <span>1</span>
                      <li>{t("Queue for")}</li>
                    </div>
                    <div
                      className={`${
                        selectStep === "consult"
                          ? "active-step"
                          : "disabled-class"
                      } ${lang === "EN" ? "leftside" : "rightside"}`}
                    >
                      <span>2</span>
                      <li>{t("Choosing a field")}</li>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${
                        selectStep === "age" ? "active-step" : ""
                      } ${lang === "EN" ? "leftside" : "rightside"}`}
                      onClick={() =>
                        doctorObj?.patientsCategory?.length !== 1
                          ? handleStep("age")
                          : undefined
                      }
                    >
                      <span>1</span>
                      <li>{t("Queue for")}</li>
                    </div>
                    <div
                      onClick={() =>
                        (formData?.consult && expertise === "null") ||
                        doctorObj?.consultationSpeciality?.length !== 1
                          ? handleStep("consult")
                          : undefined
                      }
                      className={`${
                        selectStep === "consult" ? "active-step" : ""
                      } ${lang === "EN" ? "leftside" : "rightside"}`}
                    >
                      <span>2</span>
                      <li>{t("Choosing a field")}</li>
                    </div>
                  </>
                )}
                <div
                  className={`${
                    selectStep === "reference" ? "active-step" : ""
                  } ${lang === "EN" ? "leftside" : "rightside"}`}
                  onClick={() =>
                    formData?.reference ? handleStep("reference") : undefined
                  }
                >
                  <span>3</span>
                  <li>{t("Reference description")}</li>
                </div>

                <div
                  className={`${selectStep === "time" ? "active-step" : ""} ${
                    lang === "EN" ? "leftside" : "rightside"
                  }`}
                  onClick={() =>
                    formData?.time ? handleStep("time") : undefined
                  }
                >
                  <span>4</span>
                  <li>{t("Choice of times")}</li>
                </div>
                <div
                  className={`${selectStep === "" ? "active-step" : ""} ${
                    lang === "EN" ? "leftside" : "rightside"
                  }`}
                  onClick={() =>
                    formData?.time ? handleStep("time") : undefined
                  }
                >
                  <span>5</span>
                  <li>{t("Sending")}</li>
                </div>
              </ul>
              {doctorObj ? (
                <div className="doctor-name-grid">
                  <h5>
                    {` ${
                      doctorObj?.title === "Doctor"
                        ? t("Doctor")
                        : t("Professor")
                    } ${doctorObj?.userDetails?.first_name} ${
                      doctorObj?.userDetails?.last_name
                    }`}
                  </h5>
                </div>
              ) : null}
            </div>
          </div>
          {token && <Timer />}

          <div className="container">
            <div className={`step-content ${doctorObj ? "with-doc-name" : ""}`}>
              {selectStep === "age" ? (
                <AgeForm
                  setSelectStep={setSelectStep}
                  setformData={setformData}
                  expertise={expertise}
                  consultField={consultField}
                  setConsultField={setConsultField}
                  doctorObj={doctorObj}
                  storagePartnerId={storagePartnerId}
                />
              ) : selectStep === "consult" ? (
                <ConsultingField
                  setSelectStep={setSelectStep}
                  formData={formData}
                  setformData={setformData}
                  categoriesData={categoriesData}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  doctorObj={doctorObj}
                  storagePartnerId={storagePartnerId}
                  pastMeetingObject={pastMeetingObject}
                />
              ) : selectStep === "reference" ? (
                <ReferenceDetails
                  formData={formData}
                  setformData={setformData}
                  setSelectStep={setSelectStep}
                  doctorObj={doctorObj}
                  filteredData={filteredData}
                  storagePartnerId={storagePartnerId}
                />
              ) : selectStep === "time" ? (
                <FavourableTimes
                  formData={formData}
                  setformData={setformData}
                  setSelectStep={setSelectStep}
                  doctorObj={doctorObj}
                  utm={filteredUTM[0]}
                  storagePartnerId={storagePartnerId}
                />
              ) : localStorage.getItem("role") === "admin" ? (
                <AdminConfirmation
                  formData={formData}
                  setformData={setformData}
                />
              ) : (
                <ConfirmationDetails
                  formData={formData}
                  setformData={setformData}
                  doctorObj={doctorObj}
                  utm={filteredUTM[0]}
                  refrenceData={refrenceData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <SupportChat />
    </Layout>
  );
};
export default MeetingRequestForm;
