import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Dashborad/dashboard.css";
import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import { useEffect } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";

const MeetingLog = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const [meetinglogData, setMeetingLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.meetingId) {
      meetingLogDetails();
    }
  }, [props.meetinglogModal]);
  const meetingLogDetails = async () => {
    try {
      setLoading(true);
      const response = await Network().get(
        "/admin/getMeetingLogs/" + props.meetingId,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        setMeetingLogData(response.data.meetingLogs);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={props.meetinglogModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalContent"
      >
        <MDBCard className="modal_card">
          <MDBCardBody>
            <h1>Meeting log</h1>
            <ul style={{ padding: 0 }}>
              {loading ? (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="72"
                  visible={loading}
                />
              ) : meetinglogData.length !== 0 ? (
                meetinglogData.map((data, i) => {
                  const formattedDate = `${moment(data.updatedAt).format(
                    "DD.MM.YYYY"
                  )} ${moment(data.updatedAt).format("h:mm:ss a")}`;

                  if (data?.responseBody?.StatusResponse?.StatusCode === 0) {
                    if (data?.yarpaServiceType === "mirshamHeader") {
                      return (
                        <p className="meetingLogContent" key={i}>
                          Prescription header number {data?.mirshamId} has
                          successfully been uploaded to Yarpa ({formattedDate})
                        </p>
                      );
                    }
                    if (data?.yarpaServiceType === "mirshamimLines") {
                      return (
                        <p className="meetingLogContent" key={i}>
                          Prescription lines for header number{" "}
                          {data?.requestBody?.MirshamId} has successfully been
                          uploaded to Yarpa ({formattedDate})
                        </p>
                      );
                    }
                  }

                  return (
                    <p className="meetingLogContent" key={i}>
                      {data.action} ({formattedDate})
                    </p>
                  );
                })
              ) : (
                <p className="meetingLogContent">
                  {t("This meeting has no log")}
                </p>
              )}
            </ul>
            <Button onClick={() => props.handleClose()}>{t("Close")}</Button>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default MeetingLog;
