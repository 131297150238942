import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { Button, Form } from "react-bootstrap";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { Network } from "../../../config/axios";
import {
  adminAllConsultation,
  adminAllSubscriptions,
  adminGetPartnesList,
} from "../../../config/service";
import { toast } from "react-toastify";
// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import AdminImageCropper from "./AdminImageCropper";
import profilePic from "../../../assets/images/dashboard/docProfile.png";
import Multiselect from "multiselect-react-dropdown";
import { handleKeyPress } from "../../Login/login";
import { checkEmailFormate } from "../../../utils/function";

const UpdateDetails = (props) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const {
    userdata,
    selectedValue,
    id,
    setSelectedType,
    selectedtype,
    setCalenderInvite,
    calenderInvite,
    setSelectedSubscriptions,
    selectedSubscriptions,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const [consultData, setConsultData] = useState([]);
  const [typevalue, setTypeValue] = useState([]);
  const [calendarEmail, setCalendarEmail] = useState();
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [subscriptionsValue, setSubscriptionsValue] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [isPassport, setIsPassport] = useState(false);

  const [partnersList, setPartnersList] = useState([]);
  const patienttype = [
    { eng: "Children", heb: "ילד.ה" },
    { eng: "Adults", heb: "מבוגר" },
  ];
  const [formValue, setformValue] = React.useState({
    email: "",
    first_name: "",
    last_name: "",
    id: "",
    phone: "",
    password: "",
    role: "",
    dob: "",
    sex: "",
    MaritalStatus: "",
    permanent: "",
    street: "",
    postalcode: "",
    localphoneNumber: "",
    city: "",
    houseNo: "",
    country: "Israel",
    doctor_license: "",
    title: "",
    speciality: [],
    experience: "",
    bio: "",
    UTM: "",
    collective: "",
    personalId: "",
    partnerId: "",
  });
  useEffect(() => {
    consultingFields();
    getSubscriptionsList();
    getPartnersList();
  }, []);

  useEffect(() => {
    if (userdata?.partnerId?.length > 0) {
      setSelectedPartners(
        partnersList?.filter((list) => {
          if (userdata?.partnerId.includes(list._id)) {
            return list;
          }
        })
      );
    }
  }, [userdata, partnersList]);

  const consultingFields = async () => {
    const response = await adminAllConsultation();
    setConsultData(response.data.result);
  };
  const onSelectType = (selectedList, selectedItem) => {
    setSelectedType((prevSelectedType) => [...prevSelectedType, selectedItem]);
  };

  const onRemoveType = (selectedList, removedItem) => {
    setSelectedType((prevSelectedType) =>
      prevSelectedType.filter((d) => d !== removedItem)
    );
  };

  useEffect(() => {
    const updatedTypeValue = selectedtype.map((item) => item.eng);
    setTypeValue(updatedTypeValue);
  }, [selectedtype]);
  const onSelectSubscriptions = (selectedList, selectedItem) => {
    setSelectedSubscriptions([...selectedSubscriptions, selectedItem]);
    setSubscriptionsValue([...subscriptionsValue, selectedItem]);
  };

  // const onSelectPartner = (selectedList, selectedItem) => {
  //   // setSelectedPartner([...selectedSubscriptions, selectedItem]);
  //   setPartnerValue([...partnerValue, selectedItem]);
  // };

  // const onRemovePartners = (selectedList, removedItem) => {
  //   // setSelectedSubscriptions(
  //   //   selectedSubscriptions.filter((d) => {
  //   //     return d != removedItem;
  //   //   })
  //   // );
  //   setPartnerValue(
  //     setPartnerValue.filter((d) => {
  //       return d != removedItem;
  //     })
  //   );
  // };

  const onRemoveSubscriptions = (selectedList, removedItem) => {
    setSelectedSubscriptions(
      selectedSubscriptions.filter((d) => {
        return d != removedItem;
      })
    );
    setSubscriptionsValue(
      subscriptionsValue.filter((d) => {
        return d != removedItem;
      })
    );
  };

  const [imageData1, setImageData1] = useState();
  const ImageThumb = ({ image }) => {
    return <img src={image} />;
  };
  const handleChange = (event) => {
    const { name } = event.target;
    if (name === "passportNumber") {
      setIsPassport(!isPassport);
    } else
      setformValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
  };

  const onSelect = (selectedList, selectedItem) => {
    props.setSelectedValue([...selectedValue, selectedItem]);
    props.setSelectId([...id, selectedItem._id]);
  };

  const onRemove = (selectedList, removedItem) => {
    props.setSelectedValue(
      props.selectedValue.filter((d) => {
        return d != removedItem;
      })
    );
    props.setSelectId(
      id.filter((d) => {
        return d != removedItem._id;
      })
    );
  };
  function handleUpload() {
    setModalShow(true);
  }
  const getSubscriptionsList = async () => {
    try {
      const response = await adminAllSubscriptions();
      setSubscriptionsList(response.data.subscriptions);
    } catch (error) {
      console.log(error);
    }
  };
  const getPartnersList = async () => {
    try {
      const response = await adminGetPartnesList();
      setPartnersList(response?.data?.partners);
    } catch (error) {
      console.log(error);
    }
  };

  const Updatedata = async () => {
    const data = {
      email: formValue.email ? formValue.email : userdata?.email,
      first_name: formValue.first_name
        ? formValue.first_name
        : userdata.first_name,
      last_name: formValue.last_name
        ? formValue.last_name
        : userdata?.last_name,
      id: formValue.id ? formValue.id : userdata?.id,
      phone: formValue.phone ? formValue.phone : userdata?.phone,
      password: formValue.password ? formValue.password : "",
      role: formValue.role ? formValue.role : userdata?.role,
      clientDOB: props.value,
      clientGender: formValue.sex ? formValue.sex : userdata?.clientGender,
      clientMaritalStatus: formValue.MaritalStatus
        ? formValue.MaritalStatus
        : userdata?.clientMaritalStatus,
      permanentOrRetired: formValue.permanent
        ? formValue.permanent
        : userdata?.permanentOrRetired,
      sendCalendarInvite: calenderInvite,
      calendarEmail: calendarEmail,
      clientAddress: {
        street: formValue.street
          ? formValue.street
          : userdata?.clientAddress?.street,
        pincode: formValue.postalcode
          ? formValue.postalcode
          : userdata?.clientAddress?.pincode,
        localPhone: formValue.localphoneNumber
          ? formValue.localphoneNumber
          : userdata.clientAddress?.localPhone,
        city: formValue.city ? formValue.city : userdata?.clientAddress?.city,
        house: formValue.houseNo
          ? formValue.houseNo
          : userdata?.clientAddress?.house,
        country: formValue.country
          ? formValue.country
          : userdata?.clientAddress?.country,
      },

      licenceNumber: formValue.doctor_license
        ? formValue.doctor_license
        : userdata?.doctorPersonalData?.licenceNumber,
      title: formValue.title
        ? formValue.title
        : userdata?.doctorPersonalData?.title,
      consultationSpeciality:
        id.length != 0
          ? id
          : userdata?.doctorPersonalData?.consultationSpeciality,
      experience: formValue.experience
        ? formValue.experience
        : userdata?.doctorPersonalData?.experience,
      patientsCategory:
        typevalue.length != 0
          ? typevalue
          : userdata?.doctorPersonalData?.patientsCategory,
      bio: formValue.bio ? formValue.bio : userdata?.doctorPersonalData?.bio,

      UTM: formValue.UTM ? formValue.UTM : userdata?.UTM,
      collective: formValue.collective
        ? formValue.collective
        : userdata?.collective,
      insuranceConsent: formValue.insuranceConsent
        ? formValue.insuranceConsent
        : userdata?.insuranceConsent,
      personalId: formValue.personalId
        ? formValue.personalId
        : userdata.personalId,
      subscriptions:
        selectedSubscriptions.length !== 0
          ? selectedSubscriptions
          : userdata?.doctorPersonalData?.subscriptions,
      partnerId: selectedPartners[0]?._id ? selectedPartners[0]?._id : [],
      passportNumber: isPassport,
    };
    const isEmailCorrect = checkEmailFormate(data?.email);
    if (!isEmailCorrect) {
      toast.error("Please enter valid email");
      return;
    }
    try {
      const response = await Network().patch(
        "/admin/updateUserById/" + props.updateId,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if ([200, 201, 202].includes(response.status)) {
        toast.success(t(response.data.msg));
        setformValue({
          email: "",
          first_name: "",
          last_name: "",
          id: "",
          phone: "",
          password: "",
          role: "",
          dob: "",
          sex: "",
          MaritalStatus: "",
          permanent: "",
          street: "",
          postalcode: "",
          localphoneNumber: "",
          city: "",
          houseNo: "",
          country: "Israel",
          doctor_license: "",
          title: "",
          speciality: [],
          experience: "",
          bio: "",
          UTM: "",
          collective: "",
          personalId: "",
        });
        props.handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckPassport = () => {
    if (userdata && Object.hasOwn(userdata, "passportNumber")) {
      setIsPassport(userdata?.passportNumber);
    }
  };

  useEffect(() => {
    handleCheckPassport();
  }, [userdata]);

  return (
    <div>
      <Modal
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="imagecropmodal"
      >
        <>
          <AdminImageCropper
            handleClose={() => setModalShow(false)}
            setImageData1={setImageData1}
            doctorId={props.doctorId}
          />
        </>
      </Modal>
      <Modal
        open={props.updateModal}
        onClose={() => props.setUpdateModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalDiv"
        style={{
          backgroundColor: "#FFFFFF",
          width: "80%",
          margin: "30px auto",
          height: "auto",
          borderRadius: "35px",
        }}
      >
        <MDBCard className="documentBody update-doctor-detail">
          <MDBCardBody>
            <MDBCardTitle className="headerText">
              {userdata?.role == "doctor" && t("Update Physician Details")}
              {userdata?.role == "client" && t("View User/Update User Details")}
            </MDBCardTitle>
            {userdata?.role == "doctor" ? (
              <div className="team-profile-img" onClick={handleUpload}>
                {userdata?.role == "doctor" ? (
                  imageData1 == null ? (
                    <img
                      src={userdata ? userdata.croppedAvatar : profilePic}
                      alt=""
                    />
                  ) : (
                    imageData1 && <ImageThumb image={imageData1} />
                  )
                ) : null}
              </div>
            ) : null}
            {userdata?.first_name && (
              <Form className="form">
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label> {t("First Name")}</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={handleChange}
                      id="first_name"
                      defaultValue={
                        userdata?.first_name ? userdata.first_name : ""
                      }
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    <Form.Label> {t("Last Name")}</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      onChange={handleChange}
                      id="last_name"
                      defaultValue={
                        userdata?.last_name ? userdata.last_name : ""
                      }
                    />
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label> {t("Email")}</Form.Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={userdata?.email ? userdata.email : ""}
                      name="email"
                      onChange={handleChange}
                      id="email"
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    {userdata?.role == "client" ? (
                      <Form.Label className={`id-label ${isPassport}`}>
                        {" "}
                        <p className={` ${isPassport ? "label-active" : ""}`}>
                          {" "}
                          {t("Id Number")}
                        </p>
                        <div
                          className={`slideThree ${isPassport} ${lang}`}
                          data-translation={
                            !isPassport ? t("Passport") : t("Id Number")
                          }
                        >
                          <input
                            type="checkbox"
                            value="None"
                            id="slideThree"
                            name="passportNumber"
                            checked={isPassport}
                            onChange={handleChange}
                          />
                          <label for="slideThree"></label>
                        </div>
                        <p className={`${!isPassport ? "label-active" : ""}`}>
                          {" "}
                          {t("Passport")}
                        </p>
                      </Form.Label>
                    ) : (
                      <Form.Label>{t("ID Number")}</Form.Label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      name="id"
                      onChange={handleChange}
                      id="id"
                      onWheel={(e) => e.target.blur()}
                      defaultValue={userdata?.id ? userdata.id : ""}
                      onKeyPress={handleKeyPress}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 14))
                      }
                    />
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label> {t("Phone Number")}</Form.Label>
                    <input
                      type="text"
                      className="form-control phone_number"
                      name="phone"
                      onChange={handleChange}
                      id="phone"
                      defaultValue={userdata?.phone ? userdata.phone : ""}
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    <Form.Label> {t("Role")}</Form.Label>
                    {userdata?.role && (
                      <select
                        className="form-control"
                        name="role"
                        onChange={handleChange}
                        id="role"
                        defaultValue={userdata?.role ? userdata.role : ""}
                      >
                        <option>{t("Select")}</option>
                        <option value="doctor">{t("Doctor")}</option>
                        <option value="client">{t("Client")}</option>
                      </select>
                    )}
                  </Form.Group>
                </div>

                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label>{t("Date of Birth")}</Form.Label>
                    <DatePicker
                      selected={props.value}
                      onChange={props.setDOB}
                      className="form-control"
                      dateFormat="dd-MM-y"
                      showYearDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    <Form.Label>{t("Gender")} </Form.Label>

                    <select
                      className="form-control"
                      name="sex"
                      // value={formValue.sex}
                      onChange={handleChange}
                      defaultValue={
                        userdata?.clientGender ? userdata?.clientGender : ""
                      }
                    >
                      <option value="Male">{t("Male")}</option>
                      <option value="Female">{t("Female")}</option>
                      <option value="Other"> {t("Other")}</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label>{t("Marital Status")} </Form.Label>
                    <select
                      className="form-control"
                      name="MaritalStatus"
                      // value={formValue.MaritalStatus}
                      onChange={handleChange}
                      defaultValue={
                        userdata?.clientMaritalStatus
                          ? userdata?.clientMaritalStatus
                          : ""
                      }
                    >
                      <option value="Married">{t("Married")}</option>
                      <option value="Unmarried">{t("Unmarried")}</option>
                    </select>
                  </Form.Group>
                  {userdata?.personalId || userdata?.personalId === "" ? (
                    <Form.Group className="frequency">
                      <Form.Label>{t("Personal ID Number")} </Form.Label>
                      <input
                        type="number"
                        className="form-control"
                        name="personalId"
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        id="personalId"
                        defaultValue={
                          userdata?.personalId ? userdata?.personalId : ""
                        }
                        onKeyPress={handleKeyPress}
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 14))
                        }
                      />
                    </Form.Group>
                  ) : null}
                </div>
                <div className="updatemodal">
                  {userdata?.role == "client" && (
                    <Form.Group className="frequency">
                      <Form.Label>{t("UTM")}</Form.Label>
                      <input
                        className="form-control"
                        name="UTM"
                        type="text"
                        // value={formValue.phoneNumber}
                        onChange={handleChange}
                        defaultValue={userdata?.UTM ? userdata.UTM : ""}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="frequency">
                    <Form.Label>{t("Ayalon customer")} </Form.Label>

                    <select
                      className="form-control"
                      name="insuranceConsent"
                      // value={formValue.sex}
                      onChange={handleChange}
                      defaultValue={userdata?.insuranceConsent}
                    >
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  {userdata?.role == "client" && (
                    <>
                      <Form.Group className="frequency">
                        <Form.Label>{t("Collective")}</Form.Label>
                        <input
                          className="form-control"
                          name="collective"
                          type="text"
                          // value={formValue.phoneNumber}
                          onChange={handleChange}
                          defaultValue={
                            userdata?.Collective ? userdata.Collective : ""
                          }
                        />
                      </Form.Group>
                    </>
                  )}
                </div>

                {userdata?.role == "doctor" && (
                  <>
                    <div className="updatemodal">
                      <Form.Group className="frequency">
                        <Form.Label>{t("Password")}</Form.Label>
                        <input
                          className="form-control"
                          name="password"
                          type="text"
                          // value={formValue.phoneNumber}
                          onChange={handleChange}
                          id="password"
                        />
                      </Form.Group>
                      <Form.Group className="frequency">
                        <Form.Label>{t("Doctor license")}</Form.Label>
                        <input
                          className="form-control"
                          name="doctor_license"
                          type="number"
                          // value={formValue.phoneNumber}
                          onChange={handleChange}
                          id="doctor_license"
                          defaultValue={
                            userdata?.doctorPersonalData?.licenceNumber
                              ? userdata?.doctorPersonalData?.licenceNumber
                              : ""
                          }
                        />
                      </Form.Group>
                    </div>
                    <div className="updatemodal">
                      <Form.Group className="frequency">
                        <Form.Label>{t("Specilization")} </Form.Label>
                        <Multiselect
                          className="form-control"
                          options={consultData}
                          getOptionLabel={(option) => option.eng}
                          selectedValues={selectedValue}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue={"eng"}
                          placeholder=""
                          id="consult"
                        />
                      </Form.Group>
                      <Form.Group className="frequency">
                        <Form.Label>{t("Title")}</Form.Label>
                        <select
                          className="form-control"
                          name="title"
                          onChange={handleChange}
                          defaultValue={
                            userdata?.doctorPersonalData?.title
                              ? userdata?.doctorPersonalData?.title
                              : ""
                          }
                        >
                          <option value="Professor">{t("Professor")}</option>
                          <option value="Doctor">{t("Doctor")}</option>
                        </select>
                      </Form.Group>
                    </div>

                    <div className="updatemodal">
                      <Form.Group className="frequency">
                        <Form.Label>
                          {t("Practice")} / {t("Experience")}
                        </Form.Label>
                        <input
                          className="form-control"
                          name="experience"
                          type="text"
                          // value={formValue.phoneNumber}
                          onChange={handleChange}
                          id="experience"
                          defaultValue={
                            userdata?.doctorPersonalData?.experience
                              ? userdata?.doctorPersonalData?.experience
                              : ""
                          }
                        />
                      </Form.Group>
                      <Form.Group className="frequency">
                        <Form.Label>{t("Bio")}</Form.Label>
                        <textarea
                          className="form-control"
                          maxlength="300"
                          name="bio"
                          type="text"
                          // value={formValue.phoneNumber}
                          onChange={handleChange}
                          id="bio"
                          defaultValue={
                            userdata?.doctorPersonalData?.bio
                              ? userdata?.doctorPersonalData?.bio
                              : ""
                          }
                        />
                      </Form.Group>
                    </div>
                    {userdata?.role == "doctor" && (
                      <>
                        <div className="updatemodal">
                          <Form.Group className="frequency">
                            <Form.Label>{t("Patient Type")} </Form.Label>
                            <Multiselect
                              className="form-control"
                              options={patienttype}
                              selectedValues={selectedtype}
                              onSelect={onSelectType}
                              onRemove={onRemoveType}
                              displayValue={lang === "EN" ? "eng" : "heb"}
                              placeholder=""
                            />
                          </Form.Group>
                          <Form.Group className="frequency">
                            <Form.Label>{t("Subscriptions")} </Form.Label>
                            <Multiselect
                              className="form-control"
                              options={subscriptionsList}
                              selectedValues={selectedSubscriptions}
                              onSelect={onSelectSubscriptions}
                              onRemove={onRemoveSubscriptions}
                              placeholder=""
                              isObject={false}
                            />
                          </Form.Group>
                        </div>
                        <div className="updatemodal">
                          <Form.Group className="frequency">
                            <Form.Label>{t("Partner")} </Form.Label>

                            <Multiselect
                              className="form-control"
                              options={partnersList}
                              displayValue="partnerName"
                              onSelect={(selectedList) =>
                                setSelectedPartners(selectedList)
                              }
                              onRemove={(selectedList) =>
                                setSelectedPartners(selectedList)
                              }
                              selectedValues={selectedPartners}
                              isObject={true}
                              selectionLimit={1}
                              closeOnSelect={true}
                            />

                            {/* 
                            <select
                              className="form-control"
                              id="partner"
                              name="partnerId"
                              value={formValue.partner}
                              onChange={handleChange}
                              defaultValue={userdata?.partnerId}
                            >
                              <option value="">{t("Select")}</option>
                              {partnersList &&
                                partnersList.map((el) => (
                                  <option
                                    selected={(userdata?.partnerId.indexOf(el?._id) > -1) ? true : false}
                                    value={el?._id}
                                  >
                                    {el?.partnerName}
                                  </option>
                                ))}
                            </select> */}
                          </Form.Group>
                        </div>
                      </>
                    )}
                  </>
                )}

                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label>{t("Street")}</Form.Label>
                    <input
                      className="form-control"
                      name="street"
                      type="text"
                      // value={formValue.phoneNumber}
                      onChange={handleChange}
                      id="street"
                      defaultValue={
                        userdata?.clientAddress?.street
                          ? userdata.clientAddress.street
                          : ""
                      }
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    <Form.Label>{t("House No")}</Form.Label>
                    <input
                      className="form-control"
                      name="houseNo"
                      type="text"
                      // value={formValue.phoneNumber}
                      onChange={handleChange}
                      id="houseNo"
                      defaultValue={
                        userdata?.clientAddress?.house
                          ? userdata.clientAddress.house
                          : ""
                      }
                    />
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label>{t("City")}</Form.Label>
                    <input
                      className="form-control"
                      name="city"
                      type="text"
                      // value={formValue.phoneNumber}
                      onChange={handleChange}
                      id="city"
                      defaultValue={
                        userdata?.clientAddress?.city
                          ? userdata.clientAddress.city
                          : ""
                      }
                    />
                  </Form.Group>
                  <Form.Group className="frequency">
                    <Form.Label>{t("Country")} </Form.Label>
                    <input
                      className="form-control"
                      name="country"
                      type="text"
                      id="country"
                      onChange={handleChange}
                      defaultValue={
                        userdata?.clientAddress?.country
                          ? userdata.clientAddress.country
                          : ""
                      }
                    />
                  </Form.Group>
                </div>
                <div className="updatemodal">
                  <Form.Group className="frequency">
                    <Form.Label>{t("Postal Code")}</Form.Label>
                    <input
                      className="form-control"
                      name="postalcode"
                      type="tel"
                      // value={formValue.phoneNumber}
                      onChange={handleChange}
                      id="postalcode"
                      dir="auto"
                      defaultValue={
                        userdata?.clientAddress?.pincode
                          ? userdata.clientAddress.pincode
                          : ""
                      }
                    />
                  </Form.Group>
                  {userdata?.role == "doctor" && (
                    <Form.Group className="frequency">
                      <Form.Label>
                        {t(
                          "E-mail for calendar invitations (If different from the main e-mail address)"
                        )}
                      </Form.Label>
                      <input
                        className="form-control"
                        name="calendarEmail"
                        type="tel"
                        // value={formValue.phoneNumber}
                        onChange={(e) => setCalendarEmail(e.target.value)}
                        id="postalcode"
                        dir="auto"
                        defaultValue={
                          userdata?.doctorPersonalData?.calendarEmail
                            ? userdata?.doctorPersonalData?.calendarEmail
                            : ""
                        }
                      />
                    </Form.Group>
                  )}
                </div>
                {userdata?.role == "doctor" && (
                  <div className="check">
                    <input
                      type="checkbox"
                      checked={calenderInvite}
                      onClick={() => setCalenderInvite(!calenderInvite)}
                    />
                    <span> {t("Get calendar invitations for meetings")}</span>
                  </div>
                )}
              </Form>
            )}

            <div style={{ margin: "0 auto" }}>
              <Button size="lg" onClick={Updatedata}>
                {" "}
                {t("Submit")}
              </Button>
            </div>
          </MDBCardBody>
        </MDBCard>
      </Modal>
    </div>
  );
};
export default UpdateDetails;
