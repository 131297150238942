import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/client.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button } from "react-bootstrap";
import SummaryComponent from "./SummaryComponent";
import { documentView } from "../../config/service";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { formatDate } from "../../utils/function";

function useIsButtonDisabled(scheduledDate) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const oneDayLater = new Date(
      new Date(scheduledDate).getTime() + 24 * 60 * 60 * 1000
    );

    const nowUTC = new Date().toISOString();

    if (new Date(nowUTC) >= oneDayLater) {
      setIsButtonDisabled(true);
    }
    const interval = setInterval(() => {
      const currentTimeUTC = new Date().toISOString();
      if (new Date(currentTimeUTC) >= oneDayLater) {
        setIsButtonDisabled(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [scheduledDate]);

  return isButtonDisabled;
}

const DoctorRX = (props) => {
  const { onlyRx } = props;
  const wrapperRef = useRef(null);
  const [meeting, setMeeting] = useState([]);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  const [currentUserData, setCurrentUserData] = useState();
  const [doctorData, setDoctorData] = useState();
  const screenWidth = window.innerWidth;
  const [screenSize, setScreenSize] = useState(screenWidth);
  const [summaryId, setSummaryId] = useState();
  const [updateSummaryResponce, setUpdateSummaryResponse] = useState({});
  const [prescriptionResponce, setUpdateProscriptionResponse] = useState([]);
  const [userName, setUserName] = useState("");
  const [checkIDF, setCheckIDF] = useState("");
  const [hasMeetingType, setHasMeetingType] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    setScreenSize(screenWidth);
  }, [screenWidth]);

  const handleMobileAction = (data, event) => {
    const buttonRect = event.target.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    const dropdownHeight = 120;
    const adjustedTop =
      buttonRect.bottom + dropdownHeight > viewportHeight
        ? buttonRect.top - dropdownHeight
        : buttonRect.bottom;

        if(onlyRx) {
          setPosition({
            top: adjustedTop + window.scrollY,
            left: buttonRect.left + window.scrollX ,
          });
        }
        else {
          setPosition({
            top: adjustedTop + window.scrollY - 200,
            left: buttonRect.left + window.scrollX ,
          });
        }

    setModalData(data);
    setModal(true);
  };

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: t("Patients Details"),
        field: "Patients Details",
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        width:
         screenSize >= 991
            ? "unset"
            :  screenSize <= 991
            ? 140
            : "auto",
        cellRendererFramework: (params) => {
          const patientName = params.value.split(", ")[0];
          const patientDetails = params.value.split(", ").slice(1).join(", ");

          return (
            <div
              className="name-grid"
              dir={"ltr"}
              style={{ justifyContent: lang === "HE" ? "flex-end" : "unset" }}
            >
              <h6>{patientName}</h6> <span>,</span>
              <p>{patientDetails}</p>
            </div>
          );
        },
      },
      {
        headerName: t("Created Date"),
        field: "Created Date",
        sortable: true,
        resizable: true,
        floatingFilter: true,
        sort: "desc",
        valueFormatter: (params) => {
          return params.value
            ? moment(params.value).format("DD-MM-YYYY h:mm:ss a")
            : "";
        },
        width:
           screenSize >= 991
            ? "unset"
            : screenSize <= 991
            ? "auto"
            : "auto",
        flex: screenSize <= 991 && 1,
        cellRendererFramework: (params) => {
          return (
            <div className="date-grid">
              <p>{formatDate(params.value)}</p>
            </div>
          );
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDate, cellValue) {
            filterLocalDate = new Date(filterLocalDate);
            cellValue = new Date(new Date(cellValue).setHours(0, 0, 0, 0));
            const filterBy = filterLocalDate.getTime();
            const filterMe = cellValue.getTime();
            if (filterBy === filterMe) return 0;
            return filterMe < filterBy ? -1 : 1;
          },
        },
      },
      {
        headerName: "",
        flex:
          screenSize >= 767
            ? 1
            : "unset",
        width:
        screenSize >= 767
            ? "unset"
            : screenSize <= 991
            ? 50
            : 450,
        cellRendererFramework: (params) => {
          const isButtonDisabled = useIsButtonDisabled(
            params?.data?.meetingDetail?.scheduledDate
          );
          return (
            <>
              <div className="action-grid align-items-center h-100">
                <ActionButtonGrid
                  t={t}
                  data={params?.data}
                  summaryModal={summaryModal}
                  isButtonDisabled={isButtonDisabled}
                  handleFollowupSummary={handleFollowupSummary}
                  viewPdf={viewPdf}
                />
              </div>
              <div className="dropdown-action">
                <Button onClick={(e) => handleMobileAction(params?.data, e)}>
                  {" "}
                  <MoreVertIcon />
                </Button>
              </div>
            </>
          );
        },
      },
    ];
  }, [onlyRx, t]);
  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const getallmeetings = () => {
    const filteredAppointmet = props.allAppointment.filter(
      (el) => el.meetingType === "PrescriptionOnly"
    );

    const meetingData = filteredAppointmet.map((d) => {
      return {
        "Created Date": new Date(d.createdAt),
        meetingDetail: d,
        // "Patients Name": `${
        //   d.patientDetails?.first_name ? d?.patientDetails?.first_name : ""
        // } ${d?.patientDetails?.last_name ? d?.patientDetails?.last_name : ""}`,
        Age: `${ageCalculator(d?.userDetails?.clientDOB)}, `,
        Gender: `${d?.patientDetails?.patientGender}`,
        "Patients Details": `${
          d.patientDetails?.first_name ? d?.patientDetails?.first_name : ""
        } ${
          d?.patientDetails?.last_name ? d?.patientDetails?.last_name : ""
        }, ${
          d?.userDetails?.clientDOB
            ? ageCalculator(d?.userDetails?.clientDOB)
            : "N/A"
        }, ${d?.patientDetails?.patientGender}`,
        // "Meeting Id": `${d._id}`,
      };
    });
    setMeeting(meetingData);
  };

  useEffect(() => {
    getallmeetings();
  }, [props.allAppointment]);
  const summaryModal = (e, id, prescription, summary, data) => {
    e.preventDefault();
    setUserName(
      `${data?.userDetails?.first_name}  ${data?.userDetails?.last_name}`
    );
    setCurrentUserData(data?.userDetails);
    setDoctorData(data?.doctorDetails);
    setCheckIDF(data?.consultation[0]?.meetingGroupId);
    setSummaryId(id);
    setUpdateSummaryResponse(summary);
    setUpdateProscriptionResponse(prescription);
    setHasMeetingType(
      Object.prototype.hasOwnProperty.call(data, "meetingType")
    );
    props.setOpen(true);
  };

  const handleFollowupSummary = (e, data) => {
    e.preventDefault();
    navigate("/create-prescription", { state: { meetingDetail: data } });
  };
  const viewPdf = (document) => {
    document.forEach(async (element) => {
      if (element.type == "Summary" && element.docType === "current") {
        const data = {
          url: element.path,
        };
        const response = await documentView(data);
        setTimeout(() => {
          window.open(response.data, "_blank");
        });
      }
    });
  };

  function ageCalculator(d) {
    var birthDate = new Date(d);
    var otherDate = new Date();

    var years = otherDate.getFullYear() - birthDate.getFullYear();

    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() == birthDate.getMonth() &&
        otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  }
  const handleCloseSumm = () => {
    props.handleCloseSummary();
    setUpdateSummaryResponse({});
  };

  const rowHeight = useMemo(() => {
    if (screenSize <= 991) {
      return 60;
    }
    if (onlyRx) {
      return 50;
    }
    return "default";
  }, [onlyRx, screenSize]);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="rx-doctor">
      <SummaryComponent
        open={props.open}
        updateSummaryResponce={updateSummaryResponce}
        setUpdateSummaryResponse={setUpdateSummaryResponse}
        prescriptionResponce={prescriptionResponce}
        setOpen={props.setOpen}
        meetingId={summaryId}
        handleClose={handleCloseSumm}
        AllApoinmentDoctor={props.AllApoinmentDoctor}
        setMeetingValue={props.setMeetingValue}
        meetingValue={props.meetingValue}
        userName={userName}
        checkIDF={checkIDF}
        userData={currentUserData}
        doctorData={doctorData}
        hasMeetingType={hasMeetingType}
      />

      <section className="userWrapper doctor-user">
        <div className="adminDataTable">
          <div className="container">
            {onlyRx && (
              <div className="table-title">
                <h2 style={{ textAlign: lang === "HE" ? "right" : "left" }}>
                  {" "}
                  {t("Prescriptions List")}{" "}
                </h2>
              </div>
            )}
            {modal && (
              <MobileAction
                modalData={modalData}
                position={position}
                t={t}
                summaryModal={summaryModal}
                handleFollowupSummary={handleFollowupSummary}
                viewPdf={viewPdf}
                wrapperRef={wrapperRef}
              />
            )}
            <div
              className="ag-theme-alpine"
              style={{
                width: "100%",
                height: onlyRx ? "calc(100vh - 200px)" : 450,
                borderRadius: "12px",
              }}
            >
              <AgGridReact
                key={lang}
                rowData={meeting}
                columnDefs={columnDefs}
                animateRows={true}
                rowSelection="multiple"
                onCellClicked={cellClickedListener}
                alwaysShowHorizontalScroll={true}
                alwaysShowVerticalScroll={true}
                enableRtl={lang === "HE"}
                rowHeight={rowHeight}
              />
            </div>
              <div className="add-new-rx">
                <Link to="/create-prescription">
                  <AddIcon />
                </Link>
              </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default DoctorRX;

const ActionButtonGrid = ({
  data,
  t,
  handleFollowupSummary,
  viewPdf,
  summaryModal,
  isButtonDisabled,
}) => {
  const { meetingDetail } = data || {};

  return (
    <>
      <Button
        onClick={(e) =>
          summaryModal(
            e,
            meetingDetail?._id,
            meetingDetail?.prescription,
            meetingDetail?.summary,
            meetingDetail
          )
        }
        disabled={isButtonDisabled}
      >
        {t("update")}
      </Button>

      <Button
        onClick={(e) => handleFollowupSummary(e, meetingDetail)}
        style={{ margin: "0px 12px" }}
      >
        {t("Follow up summary")}
      </Button>

      <Button
        onClick={() =>
          viewPdf(
            meetingDetail?.documents?.length ? meetingDetail?.documents : ""
          )
        }
      >
        {t("download")}
      </Button>
    </>
  );
};

const MobileAction = ({
  modalData,
  position,
  t,
  summaryModal,
  handleFollowupSummary,
  viewPdf,
  wrapperRef,
}) => {
  const isButtonDisabled = useIsButtonDisabled(
    modalData?.meetingDetail?.scheduledDate
  );
  return (
    <ul
      className="mobile-action-menu"
      style={{
        position: "absolute",
        top: `${position.top - 80}px`,
        left: `${position.left - 20}px`,
        background: "#fff",
        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
        zIndex: 1000,
        listStyle: "none",
      }}
      ref={wrapperRef}
    >
      <ActionButtonGrid
        t={t}
        data={modalData}
        summaryModal={summaryModal}
        isButtonDisabled={isButtonDisabled}
        handleFollowupSummary={handleFollowupSummary}
        viewPdf={viewPdf}
      />
    </ul>
  );
};
