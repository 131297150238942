import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RotatingLines } from "react-loader-spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Dashborad/dashboard.css";
import "react-image-crop/dist/ReactCrop.css";
import { doctorAllAppointment } from "../../config/service";
import DoctorFuture from "./DoctorFuture";
import DoctorPast from "./DoctorPast";
import DoctorPending from "./DoctorPending";
import DoctorRX from "./DoctorRx";

const DoctorDashboard = (props) => {
  const {subscriptionsList} = props
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const status = localStorage.getItem("status");
  const [allAppointment, setAllAppointment] = useState([]);
  const [dateoffer, setdateoffer] = useState([]);
  const [meetingValue, setMeetingValue] = useState("");
  const [tabClick, setTabClick] = useState(false);
  const [newOfferDate, setNewOfferDate] = useState([]);
  const [offerModal, setOfferModal] = useState(false);
  const [medicalq, setMedicalQ] = useState(false);
  const [qData, setQData] = useState();
  const [comentModal, setCommentModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [fdate, setFDate] = useState();
  const [sdate, setSDate] = useState();
  const [ftime, setFTime] = useState();
  const [stime, setSTime] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());
  const [note, setNote] = useState("");
  const [onlyRx, setOnlyRx] = useState(false);

  const handleClose = () => (
    setOfferModal(false),
    setNewOfferDate([]),
    AllApoinmentDoctor({ val: "Created" }),
    datesOfferByDoctor({ val: "DatesOffered" }),
    setSDate(),
    setFDate(),
    setFTime(),
    setSTime(),
    setStartDate(),
    setStartDate1(),
    setMeetingValue("Created")
  );
  const handleClose6 = () => (
    setMedicalQ(false),
    AllApoinmentDoctor({ val: "past" }),
    setQData({}),
    setMeetingValue("past")
  );
  const handleClose1 = () => (
    setMedicalQ(false),
    // AllApoinmentDoctor({ val: "future" }),
    setQData({}),
    setMeetingValue("future")
  );
  const handleClose2 = () => (
    setCommentModal(false),
    AllApoinmentDoctor({ val: "past" }),
    setMeetingValue("past")
  );
  const handleClose3 = () => (
    setCommentModal(false),
    AllApoinmentDoctor({ val: "future" }),
    setMeetingValue("future")
  );
  const [open, setOpen] = useState(false);
  const handleCloseSummary = () => (
    setOpen(false),
    AllApoinmentDoctor({ val: meetingValue }),
    setMeetingValue(meetingValue)
  );

  useEffect(() => {
    AllApoinmentDoctor({ val: "Created" });
    datesOfferByDoctor({ val: "DatesOffered" });
  }, []);
  const datesOfferByDoctor = async ({ val }) => {
    setLoader(true);
    const data = {
      meeting_status: val,
    };

    const response = await doctorAllAppointment(data);
    setdateoffer(response.data.result);
    setLoader(false);
  };
  const handleResetState = () => {
    setUpdateLoader(true);
    setTimeout(() => {
      setUpdateLoader(false);
    }, 1000);
  };

  const AllApoinmentDoctor = async ({ val }) => {
    setLoader(true);
    const data = {
      meeting_status: val,
    };
    const response = await doctorAllAppointment(data);
    if (
      allAppointment.length < response.data.result.length &&
      val === meetingValue &&
      meetingValue === "future"
    ) {
      handleResetState();
    }
    setAllAppointment(response.data.result);
    setTabClick(false);
    setLoader(false);
  };
  const handleTabClick = (value) => {
    if (value === "future") {
      setAllAppointment([]);
    }
    AllApoinmentDoctor({ val: value }),
      setMeetingValue(value),
      localStorage.setItem("status", value);
  };

  const hanldeMeetingValue = () => {
    switch (true) {
      case props.subscriptionsList.length === 1 &&
        props.subscriptionsList.includes("Rx"):
        setMeetingValue("Done");
        break;
      case meetingValue === "future":
        setMeetingValue("future");
        break;
      case meetingValue === "past":
        setMeetingValue("past");
        break;
      default:
        setMeetingValue("Created");
        break;
    }
  };
  useEffect(() => {
    if (
      status === "Rx" ||
      (meetingValue === "Done" && props.subscriptionsList?.includes("Rx"))
    ) {
      AllApoinmentDoctor({ val: "Done" });
      setMeetingValue("Done");
    } else {
      hanldeMeetingValue();
    }
  }, [status, meetingValue, props.subscriptionsList]);

  const handleCheckOnlyRx = () => {
    if(subscriptionsList.length === 1 &&
      subscriptionsList.includes("Rx")) {
        setOnlyRx(true)
      }
  }

  useEffect (()=> {
    handleCheckOnlyRx()
  }, [subscriptionsList])

  return (
    <div className={`${onlyRx ? "only-rx-doc" : ""}`}>
      <section className="statusWrapper doctor-dashboard">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="innerWrapper">
                {props.subscriptionsList &&
                  props.subscriptionsList?.includes("Hidoc") && (
                    <>
                      {props.subscriptionsList?.includes("Rx") && (
                        <div
                          className={`${
                            lang === "HE" ? "itemBoxHeb" : "itemBoxEng"
                          } ${meetingValue == "Done" ? "activeDashTab" : ""}`}
                          onClick={() => handleTabClick("Done")}
                        >
                          {lang === "HE" ? (
                            <h2>
                              {t("Clients")} <strong> {t("RX")}</strong>
                            </h2>
                          ) : (
                            <h2>
                              <strong> {t("RX")}</strong> {t("Clients")}
                            </h2>
                          )}
                        </div>
                      )}
                      <div
                        className={`${
                          lang === "HE" ? "itemBoxHeb" : "itemBoxEng"
                        } ${meetingValue == "past" ? "activeDashTab" : ""}`}
                        onClick={() => handleTabClick("past")}
                      >
                        {lang === "HE" ? (
                          <h2>
                            {t("APPOINTMENTS")} <strong> {t("MY PAST")}</strong>
                          </h2>
                        ) : (
                          <h2>
                            <strong> {t("MY PAST")}</strong> {t("APPOINTMENTS")}
                          </h2>
                        )}
                      </div>
                      <div
                        className={`${
                          lang === "HE" ? "itemBoxHeb" : "itemBoxEng"
                        } ${meetingValue == "Created" ? "activeDashTab" : ""}`}
                        onClick={() => handleTabClick("Created")}
                      >
                        {lang === "HE" ? (
                          <h2>
                            <strong>{t("MY PENDING REQUESTS")}</strong>
                            {t("REQUESTS")}
                          </h2>
                        ) : (
                          <h2>
                            <strong>{t("MY PENDING REQUESTS")}</strong>{" "}
                            {t("STATUS")}
                          </h2>
                        )}
                      </div>
                      <div
                        className={`${
                          lang === "HE" ? "itemBoxHeb" : "itemBoxEng"
                        } ${meetingValue == "future" ? "activeDashTab" : ""}`}
                        onClick={() => handleTabClick("future")}
                      >
                        {lang === "HE" ? (
                          <h2>
                            {t("APPOINTMENTS")}{" "}
                            <strong> {t("MY FUTURE")}</strong>
                          </h2>
                        ) : (
                          <h2>
                            <strong> {t("MY FUTURE")}</strong>{" "}
                            {t("APPOINTMENTS")}
                          </h2>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </div>
            <div className="col-lg-12">
              {updateLoader && meetingValue === "future" ? (
                <div className="futur-loader">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="48"
                    visible={updateLoader}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      {meetingValue == "future" ? (
        <DoctorFuture
          allAppointment={allAppointment}
          handleClose5={handleClose1}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          handleClose3={handleClose3}
          comentModal={comentModal}
          setCommentModal={setCommentModal}
          loader={loader}
          handleCloseSummary={handleCloseSummary}
          open={open}
          setOpen={setOpen}
          meetingValue={meetingValue}
          tabClick={tabClick}
          setNote={setNote}
          note={note}
        />
      ) : meetingValue == "Created" ? (
        <DoctorPending
          allAppointment={allAppointment}
          newOfferDate={newOfferDate}
          offerModal={offerModal}
          handleClose={handleClose}
          setNewOfferDate={setNewOfferDate}
          setOfferModal={setOfferModal}
          loader={loader}
          dateoffer={dateoffer}
          fdate={fdate}
          setFDate={setFDate}
          sdate={sdate}
          setSDate={setSDate}
          ftime={ftime}
          setFTime={setFTime}
          stime={stime}
          setSTime={setSTime}
          startDate={startDate}
          setStartDate={setStartDate}
          startDate1={startDate1}
          setStartDate1={setStartDate1}
        />
      ) : meetingValue == "past" ? (
        <DoctorPast
          allAppointment={allAppointment}
          handleClose5={handleClose6}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          handleClose2={handleClose2}
          comentModal={comentModal}
          setCommentModal={setCommentModal}
          loader={loader}
          handleCloseSummary={handleCloseSummary}
          open={open}
          setOpen={setOpen}
          AllApoinmentDoctor={AllApoinmentDoctor}
          setMeetingValue={setMeetingValue}
          meetingValue={meetingValue}
          setNote={setNote}
          note={note}
        />
      ) : meetingValue == "Done" ||
        (meetingValue == "Done" && props.subscriptionsList?.includes("Rx")) ? (
        <DoctorRX
          allAppointment={allAppointment}
          handleCloseSummary={handleCloseSummary}
          open={open}
          setOpen={setOpen}
          AllApoinmentDoctor={AllApoinmentDoctor}
          setMeetingValue={setMeetingValue}
          meetingValue={meetingValue}
          onlyRx={onlyRx}
        />
      ) : (
        <DoctorPast
          allAppointment={allAppointment}
          handleClose5={handleClose6}
          medicalq={medicalq}
          setMedicalQ={setMedicalQ}
          qData={qData}
          setQData={setQData}
          handleClose2={handleClose2}
          comentModal={comentModal}
          setCommentModal={setCommentModal}
          loader={loader}
          handleCloseSummary={handleCloseSummary}
          open={open}
          setOpen={setOpen}
          AllApoinmentDoctor={AllApoinmentDoctor}
          setMeetingValue={setMeetingValue}
          meetingValue={meetingValue}
          setNote={setNote}
          note={note}
        />
      )}
    </div>
  );
};
export default DoctorDashboard;
